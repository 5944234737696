<template>
<div class="myAssets page">
  <headers title="我的资产"></headers>
  <div class="scroll">
    <div class="assetsInfo">
      <div class="prompt">保证金(元)</div>
      <div class="amount">{{info.yue}}</div>
      <div class="operating">
        <div class="item" @click="$router.push('/Recharge')">
          <img src="../../../static/img/assets_icon1.png" alt="">
          <p>充值</p>
        </div>
        <div class="item" @click="$router.push('/withdraw')">
          <img src="../../../static/img/assets_icon2.png" alt="">
          <p>提现</p>
        </div>
      </div>
    </div>
    <ul class="list">
      <li>
          <div class="left flexCenter">
            <img src="../../../static/img/assets_icon3.png" alt="">
            <span>奖励金额</span>
          </div>
          <div class="right">{{info.jiangli}}</div>
      </li>
      <li>
          <div class="left flexCenter">
            <img src="../../../static/img/assets_icon4.png" alt="">
            <span>佣金费率</span>
          </div>
          <div class="right">{{info.yongjin}}</div>
      </li>
      <li>
          <div class="left">
            <img src="../../../static/img/assets_icon5.png" alt="">
            <span>已收款金额</span>
          </div>
          <div class="right">{{info.yishou}}</div>
      </li>
      <li>
        <div class="left flexCenter">
          <img src="../../../static/img/assets_icon6.png" alt="">
          <span>代收款金额</span>
        </div>
        <div class="right">{{info.dsk}}</div>
      </li>

    </ul>
  </div>
</div>
</template>

<script>
import headers from '@/components/headers.vue'
export default {
  name: "myAssets",
  components: {
    headers
  },
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      $api.zichan({
        token: this.$store.getters['getToken']
      }).then(res => {
        this.info = res.data;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.myAssets {
  background-color: #f7f7fe;
  .scroll {
    .assetsInfo {
      background-color: #fff;
      padding-bottom: 40px;
      .prompt {
        color: #999;
        font-size: 14px;
        text-align: left;
        padding: 20px 30px 0;
      }
      .amount {
        font-size: 30px;
        font-weight: bold;
        margin-top: 20px;
      }
      .operating {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 41px;
        margin-top: 40px;
        .item {
          width: 120px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #fff;
          background: linear-gradient(0deg,#ffd879,#ff902e);
          border-radius: 10px;
          &:last-child {
            background: linear-gradient(0deg,#b09eff,#6f64ff);
          }
          img {
            width: 26px;
            height: 23px;
            margin-right: 5px;
            margin-top: 2px;
          }
        }
      }
    }
    .list {
      background: #fff;
      margin: 15px;
      border-radius: 8px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        height: 59px;
        overflow: hidden;
        .left {
         font-size: 15px;
         img {
           width: 22px;
           height: 22px;
           margin-right: 10px;
         }
       }
        .right {
          font-size: 16px;
          width: 180px;
          text-align: right;
          word-break: break-all;
        }
      }
    }
  }
}
</style>